<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
      <el-form ref="form" :model="promoter"  label-width="100px">
        <el-form-item label="姓名"  :required="true">
          <el-input type="text"  v-model="promoter.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号"  :required="true">
          <el-input type="number"  v-model="promoter.mobile" placeholder="请输入" :disabled="isDisabledMobile"></el-input>
        </el-form-item>
        <el-form-item label="审核状态"  :required="true">
          <el-radio v-model="promoter.isApproved" :label="false">未审核</el-radio>
          <el-radio v-model="promoter.isApproved" :label="true">已审核</el-radio>
        </el-form-item>
        <el-form-item style="display: none">
          <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
        </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  TipEnum,
  UrlEnum,
  request,
    isPhone
} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "PromoterSave",
  data() {
    return {
      loading: false,
      id:0,
      isDisabledMobile:false,
      promoter:{
        name:'',
        mobile:'',
        isApproved:false
      }
    }
  },
  components: {Loading},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.isDisabledMobile=true;
      this.initData();
    }
  },
  methods:{
    submit:function (){
      if(this.promoter.name == ''){
        this.showMsgError("请填写姓名")
        return;
      }
      if(this.promoter.mobile == ''){
        this.showMsgError("请填写手机号")
        return;
      }
      if(!isPhone(this.promoter.mobile)){
        this.showMsgError("手机号码格式错误")
        return;
      }
      this.loading = true;
      let url = UrlEnum.DISTRIBUTION_PROMOTERS;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.promoter,
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url: UrlEnum.DISTRIBUTION_PROMOTERS + "/" + request('id'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.promoter = res.data;
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>
</style>
